var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"units-scr scorr-roll"},[_c('div',[_c('div',{staticClass:"unit-title universal"},[_vm._v("资质图片")]),(_vm.memberAudituserqualify.length > 0)?_c('div',{staticClass:"picture-box"},_vm._l((_vm.memberAudituserqualify),function(item,index){return _c('div',{key:index,staticClass:"picture-item"},[(item.picture.length > 0)?_c('div',[_c('div',[_vm._v(_vm._s(item.itemName))]),_c('div',{staticClass:"img"},_vm._l((item.picture),function(v,i){return _c('div',{key:i,staticClass:"upImg"},[_c('el-image',{attrs:{"src":v,"preview-src-list":item.picture}})],1)}),0)]):_vm._e()])}),0):_c('div',{staticClass:"box-message-1"},[_vm._v("暂未上传相关资质")])]),(
        _vm.UserQualification.auditStatus != 20 &&
        _vm.UserQualification.auditStatus != 10
      )?_c('div',{staticClass:"units-top"},[_c('div',{staticClass:"unit-title universal"},[_vm._v("单位信息")]),_c('div',{staticClass:"unit-box"},[_c('el-form',{ref:"form1",attrs:{"label-width":"100px","inline":true,"model":_vm.unitData,"rules":_vm.rules1}},[_c('el-form-item',{attrs:{"label":"单位名称","prop":"enterprise"}},[_c('el-input',{attrs:{"disabled":(_vm.UserQualification.auditStatus != 10 &&
                _vm.UserQualification.auditStatus != 20
                  ? false
                  : true) || _vm.unitData.signaturePdf == 'true',"placeholder":"请输入"},model:{value:(_vm.unitData.enterprise),callback:function ($$v) {_vm.$set(_vm.unitData, "enterprise", $$v)},expression:"unitData.enterprise"}})],1),_c('el-form-item',{attrs:{"label":"单位简称","prop":"shopName"}},[_c('el-input',{attrs:{"disabled":(_vm.UserQualification.auditStatus != 20 &&
                _vm.UserQualification.auditStatus != 10
                  ? false
                  : true) || _vm.unitData.signaturePdf == 'true',"placeholder":"请输入"},model:{value:(_vm.unitData.shopName),callback:function ($$v) {_vm.$set(_vm.unitData, "shopName", $$v)},expression:"unitData.shopName"}})],1),_c('el-form-item',{attrs:{"label":"证件类型","prop":"unitType"}},[_c('el-select',{staticStyle:{"width":"202px"},attrs:{"disabled":(_vm.UserQualification.auditStatus != 10 &&
                _vm.UserQualification.auditStatus != 20
                  ? false
                  : true) || _vm.unitData.signaturePdf == 'true',"placeholder":"请选择"},model:{value:(_vm.unitData.unitType),callback:function ($$v) {_vm.$set(_vm.unitData, "unitType", $$v)},expression:"unitData.unitType"}},_vm._l((_vm.userUnitType),function(item){return _c('el-option',{key:item.val,attrs:{"label":item.name,"value":String(item.val)}})}),1)],1),_c('el-form-item',{attrs:{"label":"证件号码","prop":"serialNumber"}},[_c('el-input',{attrs:{"disabled":(_vm.UserQualification.auditStatus != 10 &&
                _vm.UserQualification.auditStatus != 20
                  ? false
                  : true) || _vm.unitData.signaturePdf == 'true',"placeholder":"请输入(如:统一社会信用代码)"},model:{value:(_vm.unitData.serialNumber),callback:function ($$v) {_vm.$set(_vm.unitData, "serialNumber", $$v)},expression:"unitData.serialNumber"}})],1),(['连锁加盟', '连锁直营'].includes(_vm.unitData.typeString))?_c('el-form-item',{attrs:{"label":"连锁总部","prop":"chainHeadquarter"}},[_c('div',{staticClass:"rela"},[_c('div',{staticClass:"from-input"},[_c('FilterSelect',{ref:"Filterdata",attrs:{"placeholder":"请输入连锁总部名称","keywords":"chainHeadquarter","labelKey":"chainHeadquarter","valueKey":"id","urlType":"php","urltxt":"/User/searchChainHeadquarter","Dataurl":"data.data","width":"300px"},on:{"filterValue":function (value) {
                      _vm.unitData.chainHeadquarter = value;
                    }},model:{value:(_vm.unitData.chainHeadquarter),callback:function ($$v) {_vm.$set(_vm.unitData, "chainHeadquarter", $$v)},expression:"unitData.chainHeadquarter"}})],1),_c('div',{staticClass:"abso lianled",staticStyle:{"top":"0px","right":"-110px"},on:{"click":_vm.openserve}},[_vm._v(" +添加连锁总部 ")])])]):_vm._e()],1)],1)]):_vm._e(),(
        _vm.UserQualification.auditStatus == 20 ||
        _vm.UserQualification.auditStatus == 10
      )?_c('div',[_c('div',[_c('div',{staticClass:"title universal-title"},[_vm._v("单位信息")]),_c('div',{staticClass:"mess"},[_c('div',[_c('span',[_vm._v("单位名称")]),_vm._v(_vm._s(_vm.unitData.enterprise))]),_c('div',[_c('span',[_vm._v("单位简称")]),_vm._v(_vm._s(_vm.unitData.shopName))]),_c('div',[_c('span',[_vm._v("证件类型")]),_vm._l((_vm.userUnitType),function(item){return _c('div',{key:item.val,staticClass:"screen"},[(_vm.unitData.unitType == item.val)?_c('span',[_vm._v(_vm._s(item.name))]):_vm._e()])})],2),_c('div',[_c('span',[_vm._v("证件号码")]),_vm._v(_vm._s(_vm.unitData.serialNumber))]),_c('div',[_c('span',[_vm._v("单位内码")]),_vm._v(_vm._s(_vm.unitData.erpCode || "/"))]),_c('div',[_c('span',[_vm._v("联系人内码")]),_vm._v(_vm._s(_vm.unitData.contactsCode || "/"))])])]),_c('div',[_c('div',{staticClass:"title universal-title"},[_vm._v("收货信息")]),_c('div',{staticClass:"mess"},[_c('div',[_c('span',[_vm._v("收货人")]),_vm._v(_vm._s(_vm.consigneeMessage.consignee))]),_c('div',[_c('span',[_vm._v("收货人电话")]),_vm._v(_vm._s(_vm.consigneeMessage.mobile))]),_c('div',[_c('span',[_vm._v("所在地区")]),_vm._l((_vm.AreaList),function(item){return _c('div',{key:item.id,staticClass:"screen"},[(item.id == _vm.AreaArr[0])?_c('span',[_vm._v(_vm._s(item.name)+" "),_vm._l((item.children),function(val){return _c('span',{key:val.id},[(val.id == _vm.AreaArr[1])?_c('span',[_vm._v(_vm._s(val.name)+" "),_vm._l((val.children),function(v){return _c('span',{key:v.id},[(v.id == _vm.AreaArr[2])?_c('span',[_vm._v(_vm._s(v.name)+" ")]):_vm._e()])})],2):_vm._e()])})],2):_vm._e()])})],2),_c('div',[_c('span',[_vm._v("详细地址")]),_vm._v(_vm._s(_vm.consigneeMessage.address))])])]),_c('div',{staticClass:"goback"},[_c('div',{staticClass:"go-back",on:{"click":function($event){return _vm.$router.push({ path: '/userAudit' })}}},[_vm._v(" 返回 ")])])]):_vm._e(),(
        _vm.UserQualification.auditStatus != 20 &&
        _vm.UserQualification.auditStatus != 10
      )?_c('div',{staticClass:"units-top"},[_c('div',{staticClass:"unit-title"},[_vm._v("收货信息")]),_c('div',{staticClass:"unit-box"},[_c('el-form',{ref:"form2",attrs:{"label-width":"100px","inline":true,"model":_vm.consigneeMessage,"rules":_vm.rules2}},[_c('el-form-item',{attrs:{"label":"收货人","prop":"consignee"}},[_c('el-input',{attrs:{"disabled":_vm.UserQualification.auditStatus != 20 &&
                _vm.UserQualification.auditStatus != 10
                  ? false
                  : true,"placeholder":"请输入"},model:{value:(_vm.consigneeMessage.consignee),callback:function ($$v) {_vm.$set(_vm.consigneeMessage, "consignee", $$v)},expression:"consigneeMessage.consignee"}})],1),_c('el-form-item',{attrs:{"label":"联系电话","prop":"mobile"}},[_c('el-input',{attrs:{"disabled":_vm.UserQualification.auditStatus != 10 &&
                _vm.UserQualification.auditStatus != 20
                  ? false
                  : true,"placeholder":"请输入"},model:{value:(_vm.consigneeMessage.mobile),callback:function ($$v) {_vm.$set(_vm.consigneeMessage, "mobile", $$v)},expression:"consigneeMessage.mobile"}})],1),_c('el-form-item',{attrs:{"label":"所在地区","prop":"AreaArr"}},[_c('el-cascader',{staticStyle:{"width":"202px"},attrs:{"disabled":_vm.UserQualification.auditStatus != 10 &&
                _vm.UserQualification.auditStatus != 20
                  ? false
                  : true,"options":_vm.AreaList,"props":_vm.propsValue,"clearable":"","placeholder":"请选择(须和营业执照注册地保持一致)"},model:{value:(_vm.AreaArr),callback:function ($$v) {_vm.AreaArr=$$v},expression:"AreaArr"}})],1),_c('el-form-item',{attrs:{"label":"详细地址","prop":"address"}},[_c('el-input',{attrs:{"disabled":_vm.UserQualification.auditStatus != 10 &&
                _vm.UserQualification.auditStatus != 20
                  ? false
                  : true,"placeholder":"请输入"},model:{value:(_vm.consigneeMessage.address),callback:function ($$v) {_vm.$set(_vm.consigneeMessage, "address", $$v)},expression:"consigneeMessage.address"}})],1)],1)],1)]):_vm._e()]),(
      _vm.UserQualification.auditStatus != 10 &&
      _vm.UserQualification.auditStatus != 20
    )?[_c('div',{staticClass:"operate-btn btnbom"},[_c('div',{staticClass:"save1",on:{"click":function($event){return _vm.$router.push({ path: '/userAudit' })}}},[_vm._v(" 返回 ")]),_c('div',{staticClass:"cancel",on:{"click":_vm.postUsersInfo}},[_vm._v("下一步")])])]:_vm._e(),(_vm.dialogVischain)?_c('el-dialog',{attrs:{"title":"新建","visible":_vm.dialogVischain,"width":"450px","close-on-click-modal":false,"show-close":false},on:{"update:visible":function($event){_vm.dialogVischain=$event}}},[_c('el-form',{ref:"ruleForm",attrs:{"model":_vm.addItem,"label-width":"120px"}},[_c('el-form-item',{attrs:{"label":"连锁总部名称","prop":"","required":""}},[_c('el-input',{attrs:{"oninput":"value= value.replace(/[ ]/g,'')","placeholder":"请输入连锁总部名称","clearable":""},model:{value:(_vm.addItem.chainHeadquarter),callback:function ($$v) {_vm.$set(_vm.addItem, "chainHeadquarter", $$v)},expression:"addItem.chainHeadquarter"}})],1)],1),(_vm.addItem.repeat)?_c('div',{staticClass:"red"},[_vm._v(" 注意：已有相同的连锁总部名称（"+_vm._s(_vm.addItem.copychainHeadquarter)+"），不可新建 ")]):_vm._e(),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":function($event){_vm.dialogVischain = false}}},[_vm._v("取 消")]),_c('el-button',{attrs:{"type":"primary","loading":_vm.loading},on:{"click":_vm.preserve}},[_vm._v("确 定")])],1)],1):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }