<template>
  <div class="business">
    <div class="unit-title">资质图片</div>
    <div class="picture-box" v-if="memberAudituserqualify.length > 0">
      <div
        class="picture-item"
        v-for="(item, index) in memberAudituserqualify"
        :key="index"
      >
        <div v-if="item.picture.length > 0">
          <div>{{ item.itemName }}</div>
          <div class="img">
            <div class="upImg" v-for="(v, i) in item.picture" :key="i">
              <el-image :src="v" :preview-src-list="item.picture"></el-image>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="box-message-1" v-else>暂未上传相关资质</div>
    <div class="unit-title">
      经营类别<span>(*有效期根据上传资质进行设置)</span>
    </div>
    <div class="business-1-box">
      <el-row :gutter="20">
        <el-col :span="8" v-for="item in manageData" :key="item.id">
          <div class="business-1">
            <div class="title exceed" :title="item.name">{{ item.name }}</div>
            <div class="swith">
              <el-switch
                v-model="item.status"
                active-color="#13ce66"
                inactive-color="#c9cdd4"
                :active-value="1"
                :inactive-value="0"
              >
              </el-switch>
            </div>
            <div>
              <div class="flex">
                <div class="input-box">
                  <el-date-picker
                    editable
                    format="yyyy-MM-dd"
                    style="width: 150px"
                    :picker-options="pickerOptions"
                    :class="{ hint: item.hint }"
                    @change="
                      item.expirationTime == '9999-12-31 00:00:00'
                        ? (item.flang = true)
                        : (item.flang = false)
                    "
                    :disabled="
                      item.expirationTime == '9999-12-31 00:00:00'
                        ? true
                        : false
                    "
                    v-model="item.expirationTime"
                    value-format="yyyy-MM-dd"
                    type="date"
                    placeholder="选择有效期"
                  >
                  </el-date-picker>
                </div>
                <div class="checks">
                  <el-radio-group v-model="item.expirationTime">
                    <el-checkbox
                      v-model="item.flang"
                      @change="
                        item.expirationTime == '9999-12-31 00:00:00'
                          ? (item.expirationTime = '')
                          : (item.expirationTime = '9999-12-31 00:00:00')
                      "
                      >长期
                    </el-checkbox>
                  </el-radio-group>
                </div>
              </div>
              <div>
                <span v-if="item.hint" class="hinttxet"
                  >{{ item.name }}有效期不能为空</span
                >
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <template
      v-if="
        UserQualification.qualify.status != 3 &&
        UserQualification.qualify.status != 4
      "
    >
      <div class="operate-btn">
        <el-button @click="updateTime(2)">上一步</el-button>
        <el-button :loading="loading" type="primary" @click="toNext"
          >提交</el-button
        >
      </div>
    </template>
    <!-- 发送短信 -->
    <el-dialog
      title="发送短信"
      :visible.sync="dialogVisible"
      width="400px"
      :show-close="false"
      :close-on-click-modal="false"
    >
      <div>
        <div style="margin-bottom: 5px">签署人</div>
        <el-input
          v-model="diagItem.username"
          placeholder="请输入签署人"
          style="width: 300px"
          clearable
        ></el-input>
      </div>
      <div style="margin: 10px 0px">
        <div style="margin-bottom: 5px">签署人手机号</div>
        <el-input
          v-model="diagItem.mobile"
          placeholder="请输入手机号"
          style="width: 300px"
          clearable
        ></el-input>
      </div>
      <div class="futle" style="margin: 10px 0px">
        手机号必须是签署人本人手机号，否则无法签署。
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="nodiagest">关 闭</el-button>
        <el-button type="primary" @click="determine" :loading="loading"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapState, mapActions } = createNamespacedHelpers("Audit"); //vuex公共库
import {
  sendSms, //经营范围设置发送短信
  isTencent, //会员审核-是否腾讯电子签
  passReview, //会员审核-审核通过提交
  getAgentInfo, //获取代理人信息
} from "@/api/apiAll/phpUrl.js";
export default {
  data() {
    return {
      loading: false,
      checked: true,
      manageData: [], //经营类别数据
      pickerOptions: {
        //禁用当前日期之前的日期
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        },
      },
      memberAudituserqualify: [],

      dialogVisible: false, //发送短信弹窗
      diagItem: {
        userId: "",
        username: "", //用户名称
        name: "", //用户名称
        mobile: "", //手机号
      },
    };
  },
  computed: {
    ...mapState(["UserQualification"]),
  },
  created() {
    this.getSiness();
    this.getPicture();
  },
  methods: {
    ...mapActions([
      "getmemberAuditaddressMsg",
      "getmemberAuditscopeBusiness",
      "postmemberAuditsaveScopeBusiness",
      "postmemberAuditsavesLine",
      "postmemberAuditstatusLine",
      "postv2memberAuditsaveScopeBusiness",
      "getmemberAudituserqualify",
    ]),
    async getPicture() {
      let data = await this.getmemberAudituserqualify({
        userQualifyId: this.$route.query.id,
      });
      let qualify = [];
      data.content.qualify.forEach((v, i) => {
        if (v.detail) {
          this.$set(v, "picture", []);
          v.detail.forEach((v1) => {
            if (v1.itemType == 3) {
              v.picture.push(v1.imgUrl);
            }
          });
          qualify.push(v);
        }
      });
      this.memberAudituserqualify = [...qualify];
    },
    // gaibian
    changeCq(val) {
      //console.log(val);
    },
    // 经营范围
    async getJY() {
      let data = -(await this.getmemberAuditscopeBusiness({
        userId: this.$route.query.id,
      }));
      //console.log(data);
    },
    //更新时间线
    async updateTime(time) {
      let data = await this.postmemberAuditsavesLine({
        serialNumber: this.$route.query.id,
        userTimeLine: time,
        userId: this.$route.query.user_id,
      });
      if (data.code == 200) {
        if (time == 4) {
          return;
        } else {
          this.postmemberAuditstatusLine({
            serialNumber: this.$route.query.id,
          });
          this.loading = false;
        }
        this.$message.success(data.message);
      } else {
        this.loading = false;
        this.$message({
          message: data.message,
          type: "error",
        });
      }
    },
    // 获取经营范围信息
    async getSiness() {
      let data = await this.getmemberAuditscopeBusiness({
        userId: this.$route.query.user_id,
      });
      data.data.forEach((item) => {
        item.hint = false;
        if (item.expirationTime == "9999-12-31 00:00:00") {
          item.flang = true;
        } else {
          item.flang = false;
        }
        item.status = Number(item.status);
      });
      this.manageData = data.data;
    },
    // 长期选择切换
    checkCQ(i) {
      if (this.manageData[i].checked) {
        this.manageData[i].expirationTime = "9999-12-31 00:00:00";
      } else {
        this.manageData[i].expirationTime = "";
      }
    },
    //完成保存经营范围
    async toNext() {
      let NewArr = {
        userId: this.$route.query.user_id,
        businessEffective: [],
      };
      // this.manageData
      this.manageData.forEach((item) => {
        if (item.status == 1) {
          NewArr.businessEffective.push(item);
          if (item.expirationTime == "" || item.expirationTime == null) {
            item.hint = true;
          } else {
            item.hint = false;
          }
        }
      });
      NewArr.id = this.$route.query.id;
      NewArr.reviewId = this.$route.query.review_id;
      NewArr.status = 3;
      let num = NewArr.businessEffective.filter((item) => {
        return item.expirationTime == "" || item.expirationTime == null;
      });
      if (num.length > 0) {
        return false;
      }
      this.loading = true;
      let data = await this.postmemberAuditsaveScopeBusiness(NewArr);
      if (data.code == "000000") {
        let res1 = await passReview({
          qualifySerialNumber: this.$route.query.id,
          userId: this.$route.query.user_id,
        });
        if (res1.code == 200) {
          await this.updateTime(4);
          let res = await isTencent();
          if (res.code == 200) {
            if (res.data.istencent) {
              this.updialogVis();
            } else {
              this.loading = false;
              this.$router.push({ path: "/userAudit" });
            }
          }
        } else {
          this.loading = false;
          // this.$router.push({ path: "/userAudit" });
        }
      } else {
        this.loading = false;
        if (data.code == 90000) {
          this.$alert(
            "您审核的当前会员已重新提交更新资质申请，点击确定返回列表，请以新的信息为准进行审核。",
            "会员资质更新提醒",
            {
              confirmButtonText: "确定",
              callback: (action) => {
                this.updateTime(1);
                this.$router.push({ path: "/userAudit" });
              },
            }
          );
        } else {
          this.$message({
            message: data.message,
            type: "error",
          });
        }
      }
    },
    /** 发送短信弹窗 */
    async updialogVis() {
      this.diagItem.userId = this.$route.query.user_id;
      let res = await getAgentInfo({
        userId: this.$route.query.user_id,
      });
      if (res.code == "200") {
        Object.assign(this.diagItem, res.data);
        this.diagItem.name = res.data.username;
        this.dialogVisible = true;
      }
    },
    async determine() {
      if (this.diagItem.username == "")
        return this.$message.error("签署人不能为空");
      if (this.diagItem.mobile == "")
        return this.$message.error("签署人手机号不能为空");
      this.loading = true;
      let res = await sendSms(this.diagItem);
      this.loading = false;
      if (res.code == "200") {
        this.$message.success("发送成功");
        this.dialogVisible = false;
        this.$router.push({ path: "/userAudit" });
      }
    },
    nodiagest() {
      this.dialogVisible = false;
      this.$router.push({ path: "/userAudit" });
    },
  },
};
</script>
<style lang="scss" scoped>
.el-checkbox__input.is-checked + .el-checkbox__label {
  color: black;
}
.el-checkbox__label {
  color: black;
}
</style>
<style lang="scss" scoped>
.business {
  width: 100%;
  .business-1-box {
    width: 100%;
    max-height: 60vh;
    padding: 0px 20px;
    // display: grid;
    // grid-template-columns: repeat(3, 33.3%);
    font-size: 14px;
    // overflow-y: scroll;
    // display: flex;
    // flex-wrap: wrap;
    // align-items: flex-end;
    // justify-content: left;
    .business-1 {
      margin-top: 10px;
      // width: 40%;
      // min-width: 500px;
      height: 80px;
      line-height: 40px;
      display: flex;
      justify-content: center;
      // align-items: center;
      .title {
        min-width: 70px;
        width: 20%;
        text-align: right;
        margin-right: 5px;
        color: black !important;
        height: 100%;
      }
      .input-box {
        margin-left: 10px;
        min-width: 150px;
      }
    }
    .checks {
      margin-left: 10px;
    }
  }
  .box-message-1 {
    width: 100%;
    height: 50px;
    line-height: 50px;
  }
  .picture-box {
    display: flex;
    flex-wrap: wrap;
    // justify-content: center;
    padding: 0px 80px 40px;
    .picture-item {
      margin-right: 60px;
      margin-top: 30px;
      .img {
        display: flex;
        // align-items: center;
        .upImg {
          width: 140px;
          height: 140px;
          margin-top: 10px;
          .el-image {
            width: 140px;
            height: 140px;
          }
        }
      }
    }
  }
}
.hint {
  border: 1px solid #f00;
  height: 42px;
  border-radius: 4px;
}
.hinttxet {
  font-size: 12px;
  margin-left: 5px;
  color: #f00;
  // width: 150px !important;
  display: inline-block;
  // overflow: visible;
}
.unit-title {
  font-size: 15px;
  font-weight: 700;
  height: 30px;
  border-bottom: 1px dashed #ddd;
  display: flex;
  align-items: flex-end;
  color: #000;
  padding-bottom: 10px;
  margin-bottom: 20px;
  span {
    font-size: 12px;
    font-weight: normal;
    color: #f34444;
  }
}
.unit-title::before {
  width: 4px;
  height: 16px;
  background-color: #06b7ae;
  border-radius: 10px;
  display: inline-block;
  content: "";
  margin-right: 10px;
}
.operate-btn {
  margin-top: 40px;
}

.flex {
  display: flex;
}
</style>
