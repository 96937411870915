<template>
  <div>
    <div class="units-scr scorr-roll">
      <!-- 资质图片 -->
      <div>
        <div class="unit-title universal">资质图片</div>
        <div class="picture-box" v-if="memberAudituserqualify.length > 0">
          <div
            class="picture-item"
            v-for="(item, index) in memberAudituserqualify"
            :key="index"
          >
            <div v-if="item.picture.length > 0">
              <div>{{ item.itemName }}</div>
              <div class="img">
                <div class="upImg" v-for="(v, i) in item.picture" :key="i">
                  <el-image
                    :src="v"
                    :preview-src-list="item.picture"
                  ></el-image>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="box-message-1" v-else>暂未上传相关资质</div>
      </div>

      <!-- 单位信息 -->
      <div
        class="units-top"
        v-if="
          UserQualification.auditStatus != 20 &&
          UserQualification.auditStatus != 10
        "
      >
        <div class="unit-title universal">单位信息</div>
        <div class="unit-box">
          <el-form
            ref="form1"
            label-width="100px"
            :inline="true"
            :model="unitData"
            :rules="rules1"
          >
            <el-form-item label="单位名称" prop="enterprise">
              <el-input
                :disabled="
                  (UserQualification.auditStatus != 10 &&
                  UserQualification.auditStatus != 20
                    ? false
                    : true) || unitData.signaturePdf == 'true'
                "
                v-model="unitData.enterprise"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
            <el-form-item label="单位简称" prop="shopName">
              <el-input
                :disabled="
                  (UserQualification.auditStatus != 20 &&
                  UserQualification.auditStatus != 10
                    ? false
                    : true) || unitData.signaturePdf == 'true'
                "
                v-model="unitData.shopName"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
            <el-form-item label="证件类型" prop="unitType">
              <el-select
                style="width: 202px"
                :disabled="
                  (UserQualification.auditStatus != 10 &&
                  UserQualification.auditStatus != 20
                    ? false
                    : true) || unitData.signaturePdf == 'true'
                "
                v-model="unitData.unitType"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in userUnitType"
                  :key="item.val"
                  :label="item.name"
                  :value="String(item.val)"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="证件号码" prop="serialNumber">
              <el-input
                :disabled="
                  (UserQualification.auditStatus != 10 &&
                  UserQualification.auditStatus != 20
                    ? false
                    : true) || unitData.signaturePdf == 'true'
                "
                v-model="unitData.serialNumber"
                placeholder="请输入(如:统一社会信用代码)"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="连锁总部"
              prop="chainHeadquarter"
              v-if="['连锁加盟', '连锁直营'].includes(unitData.typeString)"
            >
              <div class="rela">
                <div class="from-input">
                  <!-- <el-input
                    placeholder="请输入连锁总部名称"
                    v-model="unitData.chainHeadquarter"
                  ></el-input> -->
                  <FilterSelect
                    ref="Filterdata"
                    v-model="unitData.chainHeadquarter"
                    @filterValue="
                      (value) => {
                        unitData.chainHeadquarter = value;
                      }
                    "
                    placeholder="请输入连锁总部名称"
                    keywords="chainHeadquarter"
                    labelKey="chainHeadquarter"
                    valueKey="id"
                    urlType="php"
                    urltxt="/User/searchChainHeadquarter"
                    Dataurl="data.data"
                    width="300px"
                  />
                </div>
                <div
                  @click="openserve"
                  class="abso lianled"
                  style="top: 0px; right: -110px"
                >
                  +添加连锁总部
                </div>
              </div>
            </el-form-item>
            <!-- <el-form-item label="单位内码" prop="erpCode">
              <el-input
                :disabled="
                  (UserQualification.auditStatus != 10 &&
                  UserQualification.auditStatus != 20
                    ? false
                    : true) || unitData.signaturePdf == 'true'
                "
                v-model="unitData.erpCode"
                placeholder="请输入（ERP往来单位内码）"
              ></el-input>
            </el-form-item>
            <el-form-item label="联系人内码" prop="contactsCode">
              <el-input
                :disabled="
                  (UserQualification.auditStatus != 10 &&
                  UserQualification.auditStatus != 20
                    ? false
                    : true) || unitData.signaturePdf == 'true'
                "
                v-model="unitData.contactsCode"
                placeholder="请输入（ERP联系人内码）"
              ></el-input>
            </el-form-item> -->
          </el-form>
        </div>
      </div>
      <!-- 审核完成 -->
      <div
        v-if="
          UserQualification.auditStatus == 20 ||
          UserQualification.auditStatus == 10
        "
      >
        <div>
          <div class="title universal-title">单位信息</div>
          <div class="mess">
            <div><span>单位名称</span>{{ unitData.enterprise }}</div>
            <div><span>单位简称</span>{{ unitData.shopName }}</div>

            <div>
              <span>证件类型</span>
              <div class="screen" v-for="item in userUnitType" :key="item.val">
                <span v-if="unitData.unitType == item.val">{{
                  item.name
                }}</span>
              </div>
            </div>
            <div><span>证件号码</span>{{ unitData.serialNumber }}</div>
            <div><span>单位内码</span>{{ unitData.erpCode || "/" }}</div>
            <div><span>联系人内码</span>{{ unitData.contactsCode || "/" }}</div>
          </div>
        </div>
        <div>
          <div class="title universal-title">收货信息</div>
          <div class="mess">
            <div><span>收货人</span>{{ consigneeMessage.consignee }}</div>
            <div><span>收货人电话</span>{{ consigneeMessage.mobile }}</div>
            <div>
              <span>所在地区</span>
              <div class="screen" v-for="item in AreaList" :key="item.id">
                <span v-if="item.id == AreaArr[0]"
                  >{{ item.name }}
                  <span v-for="val in item.children" :key="val.id">
                    <span v-if="val.id == AreaArr[1]"
                      >{{ val.name }}
                      <span v-for="v in val.children" :key="v.id">
                        <span v-if="v.id == AreaArr[2]">{{ v.name }} </span>
                      </span>
                    </span>
                  </span>
                </span>
              </div>
            </div>
            <div><span>详细地址</span>{{ consigneeMessage.address }}</div>
          </div>
        </div>
        <div class="goback">
          <div @click="$router.push({ path: '/userAudit' })" class="go-back">
            返回
          </div>
        </div>
      </div>
      <!-- 收货信息 -->
      <div
        class="units-top"
        v-if="
          UserQualification.auditStatus != 20 &&
          UserQualification.auditStatus != 10
        "
      >
        <div class="unit-title">收货信息</div>
        <div class="unit-box">
          <el-form
            ref="form2"
            label-width="100px"
            :inline="true"
            :model="consigneeMessage"
            :rules="rules2"
          >
            <el-form-item label="收货人" prop="consignee">
              <el-input
                :disabled="
                  UserQualification.auditStatus != 20 &&
                  UserQualification.auditStatus != 10
                    ? false
                    : true
                "
                v-model="consigneeMessage.consignee"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
            <el-form-item label="联系电话" prop="mobile">
              <el-input
                :disabled="
                  UserQualification.auditStatus != 10 &&
                  UserQualification.auditStatus != 20
                    ? false
                    : true
                "
                v-model="consigneeMessage.mobile"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
            <el-form-item label="所在地区" prop="AreaArr">
              <el-cascader
                style="width: 202px"
                :disabled="
                  UserQualification.auditStatus != 10 &&
                  UserQualification.auditStatus != 20
                    ? false
                    : true
                "
                v-model="AreaArr"
                :options="AreaList"
                :props="propsValue"
                clearable
                placeholder="请选择(须和营业执照注册地保持一致)"
              ></el-cascader>
            </el-form-item>
            <el-form-item label="详细地址" prop="address">
              <el-input
                :disabled="
                  UserQualification.auditStatus != 10 &&
                  UserQualification.auditStatus != 20
                    ? false
                    : true
                "
                v-model="consigneeMessage.address"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
    <template
      v-if="
        UserQualification.auditStatus != 10 &&
        UserQualification.auditStatus != 20
      "
    >
      <div class="operate-btn btnbom">
        <div class="save1" @click="$router.push({ path: '/userAudit' })">
          返回
        </div>
        <!-- <div class="save" @click="updateTime(1)">上一步</div> -->
        <div class="cancel" @click="postUsersInfo">下一步</div>
      </div>
    </template>
    <!-- 新建连锁总部弹窗 -->
    <el-dialog
      title="新建"
      :visible.sync="dialogVischain"
      width="450px"
      :close-on-click-modal="false"
      :show-close="false"
      v-if="dialogVischain"
    >
      <el-form ref="ruleForm" :model="addItem" label-width="120px">
        <el-form-item label="连锁总部名称" prop="" required>
          <el-input
            oninput="value= value.replace(/[ ]/g,'')"
            v-model="addItem.chainHeadquarter"
            placeholder="请输入连锁总部名称"
            clearable
          />
        </el-form-item>
      </el-form>
      <div v-if="addItem.repeat" class="red">
        注意：已有相同的连锁总部名称（{{
          addItem.copychainHeadquarter
        }}），不可新建
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVischain = false">取 消</el-button>
        <el-button @click="preserve" type="primary" :loading="loading"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const commonIndex = createNamespacedHelpers("commonIndex"); //vuex公共库
const { mapState, mapActions } = createNamespacedHelpers("Audit"); //vuex公共库
import {
  isExistChainHeadquarter, //会员详情-连锁总部名称是否已存在
} from "@/api/apiAll/phpUrl.js";
export default {
  data() {
    return {
      loading: false,
      memberAudituserqualify: [],
      searchData: {},
      form: {},
      propsValue: {
        value: "id",
        label: "name",
        children: "children",
      }, //级联选择器配置项
      AreaArr: [], //储存省市区
      value: "",
      unitData: {}, //单位信息
      consigneeMessage: {}, //收获信息
      rules1: {
        enterprise: [
          { required: true, message: "请填写单位名称", trigger: "blur" },
        ],
        shopName: [
          { required: true, message: "请填写单位简称", trigger: "blur" },
        ],
        unitType: [
          { required: true, message: "请选择证件类型", trigger: "blur" },
        ],
        serialNumber: [
          { required: true, message: "请填写证件号码", trigger: "blur" },
        ],
        erpCode: [
          { required: true, message: "请填写单位内码", trigger: "blur" },
        ],
        contactsCode: [
          { required: true, message: "请填写联系人内码", trigger: "blur" },
        ],
      },
      rules2: {
        // consignee: [
        //   { required: true, message: "请填写收货人", trigger: "blur" },
        // ],
        // mobile: [
        //   { required: true, message: "请填写联系电话", trigger: "blur" },
        // ],
        // AreaArr: [
        //   { required: true, message: "请选择所在地区", trigger: "blur" },
        // ],
        // address: [
        //   { required: true, message: "请填写详细地址", trigger: "blur" },
        // ],
      },

      dialogVischain: false, //连锁总部弹窗
      addItem: {
        chainHeadquarter: "", //连锁总部
        repeat: false, //是否重复
      },
    };
  },
  computed: {
    ...commonIndex.mapState([
      "AreaList",
      "userType",
      "mainHright",
      "userUnitType",
    ]),
    ...mapState(["UserQualification"]),
  },
  created() {
    this.getUnit();
    this.getShMessage();
    this.getPicture();
  },
  mounted() {
    this.$refs.Filterdata.filterValue();
  },
  methods: {
    ...mapActions([
      "getmemberAuditunit",
      "getmemberAuditaddressMsg",
      "postmemberAuditsavesLine",
      "postmemberAuditstatusLine",
      "postmemberAuditsavesaveUserInfo",
      "getmemberAudituserqualify",
    ]),
    async getPicture() {
      let data = await this.getmemberAudituserqualify({
        userQualifyId: this.$route.query.id,
      });
      let qualify = [];
      data.content.qualify.forEach((v, i) => {
        if (v.detail) {
          this.$set(v, "picture", []);
          v.detail.forEach((v1) => {
            if (v1.itemType == 3) {
              v.picture.push(v1.imgUrl);
            }
          });
          qualify.push(v);
        }
      });
      this.memberAudituserqualify = [...qualify];
      console.log(this.memberAudituserqualify, "1111111");
    },
    // 确认信息
    postUsersInfo() {
      let NewData = { ...this.unitData, ...this.consigneeMessage };
      NewData.province = this.AreaArr[0] || "";
      NewData.city = this.AreaArr[1] || "";
      NewData.district = this.AreaArr[2] || "";
      if (NewData.serialNumber) {
        NewData.serialNumber = NewData.serialNumber
          .split(/[\t\r\f\n\s]*/g)
          .join("");
      }
      this.$set(this.consigneeMessage, "AreaArr", this.AreaArr[0]);
      var valid1 = false;
      var valid2 = false;
      this.$refs.form1.validate((valid) => {
        if (valid) {
          valid1 = true;
        }
      });
      this.$refs.form2.validate((valid) => {
        if (valid) {
          valid2 = true;
        }
      });
      if (valid1 && valid2) {
        NewData.id = this.$route.query.id;
        NewData.userId = this.$route.query.user_id;
        NewData.qualifySerialNumber = this.$route.query.id;
        NewData.typeName = this.$route.query.typeName;
        this.postmemberAuditsavesaveUserInfo(NewData).then((res) => {
          if (res.code == 200) {
            this.updateTime(3);
            this.$message({
              message: res.message,
              type: "success",
            });
          } else {
            this.$message({
              message: res.message,
              type: "warning",
            });
          }
        });
      }
    },
    //更新时间线
    async updateTime(time) {
      let data = await this.postmemberAuditsavesLine({
        serialNumber: this.$route.query.id,
        userTimeLine: time,
      });
      if (data.code == 200) {
        this.postmemberAuditstatusLine({ serialNumber: this.$route.query.id });
        this.$message({
          message: data.message,
          type: "success",
        });
      } else {
        this.$message({
          message: data.message,
          type: "error",
        });
      }
    },
    //单位信息
    async getUnit() {
      let data = await this.getmemberAuditunit({ id: this.$route.query.id });
      if (data.code == "000000") {
        this.unitData = data.content;
        this.unitData.chainHeadquarter = this.unitData.chainHeadquarter || "";
      } else {
      }
    },
    // 收货信息
    async getShMessage() {
      let data = await this.getmemberAuditaddressMsg({
        userId: this.$route.query.user_id,
      });
      if (data.code == "000000") {
        if (data.content != null) {
          this.consigneeMessage = data.content;
          this.AreaArr = [
            data.content.province,
            data.content.city,
            data.content.district,
          ];
        } else {
          this.consigneeMessage = {};
          this.AreaArr = [];
        }
      }
    },
    // 下一步
    toNext() {},
    /** 新建连锁总部弹窗 */
    openserve() {
      Object.assign(this.addItem, {
        chainHeadquarter: "", //连锁总部
        repeat: false, //是否重复
      });
      this.dialogVischain = true;
    },
    /** 新建连锁总部 */
    async preserve() {
      if (this.addItem.chainHeadquarter == "")
        return this.$message.error("请输入连锁总部");
      this.loading = true;
      let res = await isExistChainHeadquarter({
        chainHeadquarter: this.addItem.chainHeadquarter,
      });
      this.loading = false;
      if (res.code == "200") {
        if (res.data.isExist == 0) {
          this.addItem.copychainHeadquarter = this.addItem.chainHeadquarter;
          this.addItem.repeat = true;
        } else {
          this.$message.success("新建连锁总部成功");
          this.addItem.repeat = false;
          this.dialogVischain = false;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.units-top {
  margin-top: 30px;
}
.unit-title {
  font-size: 15px;
  font-weight: 700;
  height: 30px;
  border-bottom: 1px dashed #ddd;
  display: flex;
  align-items: flex-end;
  color: #000;
  padding-bottom: 10px;
  margin-bottom: 20px;
}
.unit-title::before {
  width: 4px;
  height: 16px;
  background-color: #06b7ae;
  border-radius: 10px;
  display: inline-block;
  content: "";
  margin-right: 10px;
}
.picture-box {
  display: flex;
  flex-wrap: wrap;
  // justify-content: center;
  padding: 0px 80px 10px;
  .picture-item {
    margin-right: 60px;
    margin-top: 30px;
    .img {
      display: flex;
      // align-items: center;
      .upImg {
        width: 140px;
        height: 140px;
        margin-top: 10px;
        .el-image {
          width: 140px;
          height: 140px;
        }
      }
    }
  }
}
.units-scr {
  width: 100%;
  max-height: 60vh;
}
.title {
  font-weight: bold;
  // line-height: 36px;
}
.mess {
  padding: 10px 20px;
  display: flex;
  flex-wrap: wrap;
  font-size: 14px;
  // border: 1px solid #999;
  line-height: 40px;
  margin-top: 20px;
  margin-bottom: 80px;
  > div {
    width: 50%;
    color: #333;
    > span {
      width: 100px;
      display: inline-block;
      text-align: end;
      color: #666;
      margin-right: 40px;
    }
    .screen {
      display: inline-block;
    }
  }
}
.goback {
  margin-bottom: 20px;
  text-align: center;
  display: flex;
  justify-content: center;
  // div{
  //   width: 91px;
  //   height: 36px;
  //   line-height: 36px;
  //   background-color: #F2F2F2;
  //   color: #666;
  //   font-size: 14px;
  //   cursor: pointer;
  // }
}
.unit-box {
  .el-form-item {
    width: 30%;
  }
}
.btn-box {
  cursor: pointer;
  margin-top: 10px;
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  font-size: 14px;
  font-weight: 700;
  .btn-1 {
    padding: 20px;
    background: #00bfbf;
    color: #fff;
    border-radius: 5px;
    margin-right: 20px;
  }
  .btn-2 {
    color: black;
    background: #d7d7d7;
  }
}
.color {
  width: 50%;
  min-width: 250px;
  width: 100%;
  font-size: 12px;
  color: #dcdfe6;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.btnbom {
  position: fixed;
  bottom: 50px;
  left: 0px;
}
</style>
